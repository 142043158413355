<template>
  <div class="app-data-filter__checkbox-group">
    <button
      :id="ariaLabelGroupToggler"
      :aria-expanded="opened ? 'true' : 'false'"
      class="app-data-filter__button app-data-filter__checkbox-group-opener"
      @click="toggleOpenGroup"
    >
      <span class="app-data-filter__checkbox-group-title"
        >{{ group.title }}<span v-show="checkedValues.length">: {{ checkedValues.length }}</span>
      </span>

      <span v-if="!opened" class="app-data-filter__checkbox-group-opener-icon">+</span>
      <span v-else class="app-data-filter__checkbox-group-opener-icon opened">–</span>
    </button>

    <CollapseTransition :duration="200">
      <div v-if="opened" class="app-data-filter__checkbox-group-items" :aria-labelledby="ariaLabelGroupToggler">
        <div class="app-data-filter__checkbox-group-all-actions">
          <button type="button" class="app-data-filter__button" :class="{ active: selectedAll }" @click="selectAll">
            Select all
          </button>
          <button type="button" class="app-data-filter__button" @click="clearAll">Clear all</button>
        </div>

        <div v-if="group.items.length" class="app-data-filter__checkbox-group-items-list">
          <AppDataFilterCheckboxGroupItem
            v-for="item in group.items"
            :key="`${group.itemsType}-${item.id}`"
            v-model="checkedValues"
            :item="item"
            @change="onChange"
          />
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import AppDataFilterCheckboxGroupItem from './AppDataFilterCheckboxGroupItem';
import CollapseTransition from '@/components/transitions/CollapseTransition';

const isObject = (value) => typeof value === 'object' && value !== null;

const areArraysEqual = (a, b) =>
  a.length === b.length &&
  a.every((aVal, i) => {
    const aValue = isObject(aVal) ? aVal._filter : aVal;
    const bValue = isObject(b[i]) ? b[i]._filter : b[i];
    return aValue === bValue;
  });

export default {
  name: 'AppDataFilterCheckboxGroup',
  components: { CollapseTransition, AppDataFilterCheckboxGroupItem },
  props: {
    group: {
      type: Object,
      required: true,
    },
    toggler: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedValues: [],
      opened: false,
    };
  },
  computed: {
    ariaLabelGroupToggler() {
      return `${this.group.itemsType}GroupToggler`;
    },
    realCheckedValues() {
      return this.group.items
        .map((item) => {
          const valueAsString = isObject(item.value) ? item.value._filter : item.value;
          return this.checkedValues.includes(valueAsString) ? item.value : null;
        })
        .filter((v) => v);
    },
    selectedAll() {
      return this.checkedValues.length === this.group.items.length;
    },
  },
  watch: {
    toggler: {
      immediate: true,
      handler(newValue) {
        this.opened = newValue;
      },
    },
    value: {
      handler(newVal, oldVal) {
        if (oldVal === undefined || !areArraysEqual(oldVal, newVal)) {
          const checked = newVal.map((v) => (isObject(v) ? v._filter : v));

          this.checkedValues =
            oldVal !== undefined
              ? checked
              : this.group.items
                  .map((item) => {
                    const valueAsString = isObject(item.value) ? item.value._filter : item.value;
                    return checked.includes(valueAsString) ? valueAsString : null;
                  })
                  .filter((v) => v);
        }
      },
      immediate: true,
    },
  },
  methods: {
    toggleOpenGroup() {
      this.opened = !this.opened;
      this.$emit('toggle', { itemsType: this.group.itemsType, opened: this.opened });
    },
    onChange() {
      this.$emit('update', this.realCheckedValues);
    },
    selectAll() {
      if (this.selectedAll) {
        return;
      }
      this.group.items.forEach((item) => {
        const valueAsString = isObject(item.value) ? item.value._filter : item.value;
        if (!this.checkedValues.includes(valueAsString)) {
          this.checkedValues.push(valueAsString);
        }
      });
      this.onChange();
    },
    clearAll() {
      if (!this.checkedValues.length) {
        return;
      }
      this.checkedValues = [];
      this.onChange();
    },
  },
};
</script>
