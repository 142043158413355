<template>
  <div v-if="group.items.length" class="app-data-filter__checkbox-group">
    <AppDataFilterRadioGroupItem
      v-for="(item, i) in group.items"
      :key="`${group.itemsType}-radio${i}`"
      v-model="checkedValue"
      :item="item"
      @change="onChange"
    />
  </div>
</template>

<script>
import AppDataFilterRadioGroupItem from './AppDataFilterRadioGroupItem';

const isObject = (value) => typeof value === 'object' && value !== null;

export default {
  name: 'AppDataFilterRadioGroup',
  components: { AppDataFilterRadioGroupItem },
  props: {
    group: {
      type: Object,
      default: () => {
        return {
          itemsType: '',
          items: [],
        };
      },
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
  },
  data() {
    return {
      checkedValue: null,
    };
  },
  computed: {
    realCheckedValue() {
      const itemResult = this.group.items.find((item) => {
        const valueAsString = isObject(item.value) ? item.value._filter : item.value;
        return this.checkedValue === valueAsString;
      });
      return itemResult ? itemResult.value : null;
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        const oldValue = isObject(oldVal) ? oldVal._filter : oldVal;
        const newValue = isObject(newVal) ? newVal._filter : newVal;
        if (newValue !== oldValue) {
          this.checkedValue = newValue;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('update', this.realCheckedValue);
    },
  },
};
</script>
