<template>
  <Transition name="slide">
    <AppDataFilter
      v-if="isOpened"
      :id="filterId"
      :filter-groups="filterGroups"
      :value="value"
      @close="handleClose"
      @update="onUpdate"
    >
      <template #bottom-content>
        <slot name="bottom-content" />
      </template>
    </AppDataFilter>
  </Transition>
</template>

<script>
import AppDataFilter from './AppDataFilter';

export default {
  name: 'AppDataFilterMain',
  components: { AppDataFilter },
  props: {
    filterId: {
      type: String,
      default: 'dataFilter',
    },
    filterGroups: {
      type: Array,
      required: true,
    },
    initOpen: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpened: false,
    };
  },
  watch: {
    initOpen: {
      handler(opened) {
        if (opened) {
          this.handleOpen();
        } else {
          this.handleClose();
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleOpen() {
      if (this.isOpened) {
        return;
      }
      this.isOpened = true;
      if (this.isMobile) {
        this.$store.dispatch('disableScroll');
      }
      this.$emit('open');
    },
    handleClose() {
      if (!this.isOpened) {
        return;
      }
      this.isOpened = false;
      if (this.isMobile) {
        this.$store.dispatch('enableScroll');
      }
      this.$emit('close');
    },
    onUpdate({ itemsType, values }) {
      this.$emit('input', { ...this.value, [itemsType]: values });
    },
  },
};
</script>
