<template>
  <label
    class="app-data-filter__group-item app-data-filter__group-item--radio"
    :class="{ 'app-data-filter__group-item--radio-checked': isChecked }"
  >
    <input v-model="model" type="radio" :value="valueAsString" @click="uncheck" />
    <span class="app-data-filter__group-item-title">{{ label }}</span>
    <TheCheckedMarkIcon class="app-data-filter__group-item-icon-checked" />
  </label>
</template>

<script>
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';

export default {
  name: 'AppDataFilterRadioGroupItem',
  components: { TheCheckedMarkIcon },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    item: {
      type: [String, Number, Boolean, Object],
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null,
    },
  },
  computed: {
    isChecked() {
      return this.valueAsString === this.value;
    },
    isObject() {
      return typeof this.item === 'object' && this.item !== null;
    },
    label() {
      return this.isObject ? this.item.title : this.item;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    valueAsString() {
      return this.isObject ? this.item.value._filter || this.item.value : this.item;
    },
  },
  methods: {
    uncheck() {
      if (this.value !== null && this.isChecked) {
        this.$emit('change', null);
      }
    },
  },
};
</script>
