<template>
  <label class="app-data-filter__group-item">
    <input v-model="model" type="checkbox" :value="valueAsString" />
    <span class="app-data-filter__group-item-title">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'AppDataFilterCheckboxGroupItem',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    item: {
      type: [String, Number, Boolean, Object],
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isObject() {
      return typeof this.item === 'object' && this.item !== null;
    },
    label() {
      return this.isObject ? this.item.title : this.item;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    valueAsString() {
      return this.isObject ? this.item.value._filter || this.item.value : this.item;
    },
  },
};
</script>
