<template>
  <div :id="id" v-click-outside="closeFilter" class="app-data-filter" :aria-labelledby="`${id}Toggler`">
    <div class="app-data-filter__go-back">
      <button class="app-data-filter__button app-data-filter__go-back-btn" aria-label="Close" @click="closeFilter">
        <AppGoBackIcon class="app-data-filter__go-back-btn-icon" />
      </button>
    </div>
    <div class="app-data-filter__header">
      <span class="app-data-filter__header-label">Filters</span>
      <button class="app-data-filter__button app-data-filter__btn-close" aria-label="Close" @click="closeFilter">
        <AppCrossIcon class="app-data-filter__btn-close-icon" />
      </button>
    </div>

    <div class="app-data-filter__body">
      <template v-for="group in filterGroups">
        <AppDataFilterCheckboxGroup
          v-if="group.type === 'checkbox'"
          :key="group.itemsType"
          :group="group"
          :toggler="checkboxGroupToggles[group.itemsType] || false"
          :value="value[group.itemsType]"
          @toggle="onToggleGroup"
          @update="updateValues($event, group.itemsType, group.type)"
        />
        <AppDataFilterRadioGroup
          v-else-if="group.type === 'radio'"
          :key="group.itemsType"
          :group="group"
          :value="value[group.itemsType]"
          @update="updateValues($event, group.itemsType, group.type)"
        />
      </template>
    </div>

    <div>
      <slot name="bottom-content" />
    </div>
  </div>
</template>

<script>
import AppCrossIcon from '@/components/icons/AppCrossIcon';
import AppGoBackIcon from '@/components/icons/AppGoBackIcon';
import AppDataFilterCheckboxGroup from './AppDataFilterCheckboxGroup';
import AppDataFilterRadioGroup from './AppDataFilterRadioGroup';

export default {
  name: 'AppDataFilter',
  components: { AppCrossIcon, AppGoBackIcon, AppDataFilterCheckboxGroup, AppDataFilterRadioGroup },
  props: {
    filterGroups: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: 'dataFilter',
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkboxGroupToggles: {},
    };
  },
  created() {
    this.checkboxGroupToggles = this.filterGroups.reduce((toggles, group) => {
      const value = group.type === 'checkbox' ? { [group.itemsType]: this.filterGroups.length === 1 } : {};
      return { ...toggles, ...value };
    }, {});
  },
  methods: {
    closeFilter() {
      this.$emit('close');
    },
    onToggleGroup({ itemsType: activeItemsType, opened }) {
      for (const itemsType in this.checkboxGroupToggles) {
        this.checkboxGroupToggles[itemsType] = itemsType === activeItemsType ? opened : false;
      }
    },
    updateValues(values, itemsType, type) {
      if (type === 'radio') {
        this.onToggleGroup({ itemsType: '' });
      }
      this.$emit('update', { itemsType, values });
    },
  },
};
</script>
